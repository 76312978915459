<template>
<div class="content">
		<v-row no-gutters class="flex-column">
			<v-col cols="12">
				<p
					class="team-title text-md-h2 text-subtitle-1 font-weight-black vue-editor"
					v-html="trans('about_us_page_team_title')"
				/>
			</v-col>
			<v-col cols=12>
				<div class="item-list d-flex flex-column flex-md-row justify-center justify-md-space-between flex-wrap">
					<div
						v-for="member in state.team"
						:key="member.id"
						class="d-flex flex-column align-center mr-md-6"
					>
						<div class="member-item mb-8 mb-md-10">
							<v-sheet
								width="200"
								height="200"
								class="picture"
							>
								<v-img height="200" :src="member.imageUrl" />
							</v-sheet>
							<v-sheet
								v-if="member.linked_in"
								width="80"
								height="80"
								class="icon"
							>
								<a :href="member.linked_in" target="_blank">
									<v-img src="@/assets/images/linked-in.svg" />
								</a>
							</v-sheet>
						</div>
						<div class="mb-3">
							<span
								class="text-subtitle-1 font-weight-black mr-md-2 mr-1"
								v-html="member.first_name"
							/>
							<span
								class="text-subtitle-1 font-weight-black"
								v-html="member.last_name"
							/>
						</div>
						<span
							class="text-body-1 font-weight-bold grayText--text"
							v-html="member.position"
						/>
					</div>
				</div>
			</v-col>
		</v-row>
</div>
</template>

<script>
import ApiTeam from '@/api/team.js'

export default {
	name: 'Team',
	created () {
		ApiTeam.getAll(this)
	}
}
</script>

<style lang="scss" scoped>
.team-title {
	margin-bottom: 100px !important;
}
.item-list {
	row-gap: 60px;
}
.member-item {
	position: relative;
}
.picture {
	border-radius: 50% !important;
	overflow: hidden;
}
.picture::v-deep() {
	.v-image {
		border-radius: 50% !important;
	}
}
.icon {
	position: absolute;
	background: transparent;
	right: 0;
	bottom: 0;
	transition: all .4s;
  cursor: pointer;
	&:hover {
		transform: scale(1.1);
	}
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
	.team-title {
		margin-bottom: 40px !important;
	}
}
</style>

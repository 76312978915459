import ApiBase from '@/api/base'

class ApiTeam {
  static getAll (self) {
    self.http('get', `${ApiBase.baseApiUrl()}/team-members`)
      .then(response => {
        ApiBase.setToStore(null, 'setTeamList', response.data)
      })
  }
}

export default ApiTeam

import ApiBase from '@/api/base'

class ApiPartners {
  static async getAll (self) {
		try {
			let response = await self.http('get', `${ApiBase.baseApiUrl()}/partners`)
			ApiBase.setToStore(null, 'setPartners', response.data)
		} catch (error) {
			console.error('error', error)
		}
  }
}

export default ApiPartners

<template>
<div class="content">
		<v-row no-gutters class="flex-column">
			<v-col cols="12">
				<p
					class="text-md-h2 text-subtitle-1 font-weight-black mb-5 mb-md-0"
					v-html="title"
				/>
			</v-col>
			<v-col cols="12" md="11">
				<p
					class="sub-title mt-md-8 mr-md-15 text-md-subtitle-1 text-body-2 grayText--text"
					v-html="content"
				/>
			</v-col>
		</v-row>
</div>
</template>

<script>
export default {
	name: 'TextSection',
	props: {
		title: {
			type: String,
			required: true
		},
		content: {
			type: String,
			required: true
		}
	}
}
</script>

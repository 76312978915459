<template>
<div>
	<div class="content">
		<v-row no-gutters class="flex-column">
			<v-col cols="12">
				<p
					class="partners-title text-md-h2 text-subtitle-1 font-weight-black vue-editor"
					v-html="trans('about_us_partners_title')"
				/>
			</v-col>
		</v-row>
	</div>
	<div
		class="logo-block"
		:style="{ width: logoBlockWidth + 'vw' }"
	>
		<div
			v-if="logoList && logoList.length"
			class="marquee-content d-flex align-center py-4 py-md-7"
		>
			<div
				v-for="(logo, index) in logoList"
				:key="index"
				class="logo-item d-flex align-center justify-center"
				:style="{ width: logoWidth + 'vw' }"
			>
				<div
					v-if="logo.imageUrl"
					class="logo-image mr-4 mr-md-7"
					:style="{ 'background-image': 'url(' + logo.imageUrl + ')' }"
				/>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import ApiPartners from '@/api/partners.js'

export default {
	name: 'PartnersSection',
	data: () => ({
		logoBlockWidth: 100,
	}),
	mounted () {
		this.init()
	},
	computed: {
		logoWidth () {
			return this.logoBlockWidth / this.numberDisplayedLogos
		},
		logoList () {
			if (!this.state.partners || !this.state.partners.length) return []
			let list = Array.from(this.state.partners)
			for(let i = 0; i < this.numberDisplayedLogos; i++) {
				list.push(list[i])
			}
			return list
		},
		logoKeyFrame () {
			return [
				{
					transform: 'translateX(0vw)'
				},
				{
					transform: 'translateX(-' + this.translateValue + 'vw)'
				}
			]
		},
		translateValue () {
			if (!this.state.partners.length || !this.logoWidth) return
			return this.state.partners.length * this.logoWidth
		},
		animationTiming () {
			return {
				duration: this.duration,
				iterations: Infinity,
			}
		},
		duration () {
			if (!this.state.partners.length) return
			return this.state.partners.length * 2000
		},
		numberDisplayedLogos () {
			return this.$vuetify.breakpoint.smAndDown ? 2 : 6
		}
	},
	methods: {
		async init () {
			await ApiPartners.getAll(this)
			this.animate()
		},
		animate () {
			setTimeout(() => {
				let block = document.querySelector('.marquee-content')
				if (!block || !this.animationTiming || !this.logoKeyFrame.length) return
				block.animate(this.logoKeyFrame, this.animationTiming)
			}, 100)
		}
	},
	watch: {
		'$vuetify.breakpoint.smAndDown': function () {
			this.animate()
		}
	}
}
</script>

<style lang="scss" scoped>
.partners-title {
	margin-bottom: 100px !important;
}
.logo-block {
	height: 107px;
	overflow: hidden;
	background-color: rgba($color: #000000, $alpha: 0.1);
}
.marquee-content {
	list-style: none;
	height: 100%;
}
.logo-item {
	flex-shrink: 0;
	height: 100%;
}
.logo-image {
	width: 100%;
	height: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
	.partners-title {
		margin-bottom: 40px !important;
	}
	.logo-block {
		height: 67px;
	}
}
</style>

<template>
<div class="logo-wrapper">
	<div class="shadow main-green--background" />
	<div class="logo-background" />
	<div class="logo-content white--background d-flex flex-column align-center justify-center pa-8 pa-md-13">
		<v-sheet
			:width="$vuetify.breakpoint.mdAndDown ? '140' : '200'"
			:height="$vuetify.breakpoint.mdAndDown ? '202' : '288'"
		>
			<v-img src="@/assets/images/brand-guide-main-logo.svg" />
		</v-sheet>
	</div>
</div>
</template>

<script>
export default {
	name: 'PreviewLogo'
}
</script>

<style lang="scss" scoped>
.logo-wrapper {
	position: relative;
	width: 500px;
	min-height: 680px;
	border-radius: 42px;
	box-shadow: 0px 20px 40px #2D343629;
	overflow: hidden;
}
.shadow {
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0.35;
}
.logo-background {
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0.35;
	background: #FFFFFF 0% 0% no-repeat padding-box;
}
.logo-content {
	margin: 10px;
	width: calc(100% - 20px);
	min-height: calc(100% - 20px);
	position: absolute;
	border-radius: 40px;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
	.logo-wrapper {
		width: 100%;
		min-height: 444px;
		border-radius: 27px;
	}
	.logo-content {
		margin: 5px;
		width: calc(100% - 10px);
		min-height: calc(100% - 10px);
		border-radius: 25px;
	}
}
</style>

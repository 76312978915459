import Subheader from '@/components/about-us/subheader/Subheader.vue'
import TextSection from '@/components/about-us/TextSection'
import Team from '@/components/about-us/Team'
import BecomeMember from '@/components/about-us/BecomeMember'
import PartnersSection from '@/components/about-us/PartnersSection'
import CardRow from '@/components/card-row/CardRow.vue'

export default {
  name: 'AboutUs',
  components: {
    Subheader,
    TextSection,
    Team,
    BecomeMember,
		PartnersSection,
		CardRow
  },
	metaInfo () {
		return {
			title: 'About Us',
			meta: [{
				name: 'description',
				content: 'Topic description about "About Us" page',
			},
			{
				name: 'og:url',
				content: this.createCanonicalUrl(),
			},
			{
				name: 'og:type',
				content: 'website',
			},
			{
				name: 'og:title',
				content: 'About Us',
			},
			{
				name: 'og:description',
				content: 'Topic description about "About Us" page',
			},
			{
				name: 'og:image',
				content: require('@/assets/images/charts-with-shadow.png'),
			},
			{
				name: 'twitter:card',
				content: 'summary_large_image',
			},
			{
				name: 'twitter:domain',
				content: '1tn.com',
			},
			{
				name: 'twitter:url',
				content: this.createCanonicalUrl(),
			},
			{
				name: 'twitter:title',
				content: 'About Us',
			},
			{
				name: 'twitter:description',
				content: 'Topic description about "About Us" page',
			},
			{
				name: 'twitter:image',
				content: require('@/assets/images/charts-with-shadow.png'),
			}
			],
			link: [{
				rel: 'canonical',
				href: this.createCanonicalUrl()
			}]
		}
	},
	computed: {
    items () {
      return [
        {
          src: require('@/assets/images/about-us-transparency-icon.svg'),
          title: this.trans('about_us_page_culture_item_1_title'),
          description: this.trans('about_us_page_culture_item_1_content'),
        },
        {
          src: require('@/assets/images/about-us-security-icon.svg'),
          title: this.trans('about_us_page_culture_item_2_title'),
          description: this.trans('about_us_page_culture_item_2_content'),
        },
        {
          src: require('@/assets/images/about-us-innovation-icon.svg'),
          title: this.trans('about_us_page_culture_item_3_title'),
          description: this.trans('about_us_page_culture_item_3_content'),
        },
        {
          src: require('@/assets/images/about-us-responsiveness-icon.svg'),
          title: this.trans('about_us_page_culture_item_4_title'),
          description: this.trans('about_us_page_culture_item_4_content'),
        }
      ]
    }
  }
}

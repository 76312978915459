<template>
	<div class="content">
		<v-row no-gutters class="flex-column mb-10 mb-md-15">
			<v-col cols="12" align-self="center">
				<p
					class="text-md-h2 text-body-1 text-center font-weight-black vue-editor"
					v-html="trans('about_us_page_become_partner_title')"
				/>
			</v-col>
		</v-row>
		<v-row no-gutters>
			<v-col cols="12" class="d-flex justify-center">
				<BecomeMemberBtn />
			</v-col>
		</v-row>
	</div>
</template>

<script>
import BecomeMemberBtn from '@/components/about-us/about-us-buttons/BecomeMemberBtn'

export default {
	name: 'BecomeMember',
	components: {
		BecomeMemberBtn
	}
}
</script>
